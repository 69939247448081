import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Button, InputGroup, FloatingLabel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import * as Icon from 'react-bootstrap-icons';
import PCMC from '../../Assets/PCMC-LOGO.png';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData, setLogout } from '../Auth/LoginSlice';
import { setActiveKey, setImgVerifyReport } from '../Common/ActiveKeySlice';


const Login = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector(state => state?.surveylogin?.userData);


  // const loading = useSelector(state => state.loginData.loading);
  // console.log("userState", userState);
  //   if(userState===null){
  //     navigate('/');
  //     console.log("Cant Navigate");
  //   }

  useEffect(() => {
    if (userState) {
      if (userState.message === "Wrong Password") {
        toast.error("Please enter valid password");
        setLoader(false);
        dispatch(setLogout(null));
      }
      else if (userState.message === 'No user found') {
        toast.warning("Invalid credentials, No user found");
        setLoader(false);
        dispatch(setLogout(null));
      }
      else if (userState?.data?.userType_keyname === "admin" || userState?.data?.userType_keyname === "mavim"
        || userState?.data?.userType_keyname === "dataoperator" || userState?.data?.userType_keyname === "tso"
        || userState?.data?.userType_keyname === "clerk" || userState?.data?.userType_keyname === "fireofficer"
        || userState?.data?.userType_keyname === "pollutioncontroller") {
        setLoader(false);
        dispatch(setActiveKey({ activeKey: 1, subActivekey: 1, activeTab: 'Dashboard' }));
        navigate('/dashboard');
      }
      else {
        setLoader(false);
        toast.error('Invalid Login Request');
        dispatch(setLogout(null));
      }
    }
  }, [userState])


  const [mobile, setMobile] = useState('');
  const [pass, setPass] = useState('');

  const [loader, setLoader] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const validate = () => {
    let FormValid = true;
    if (!mobile) {
      FormValid = false;
      toast.error("Please enter valid mobile No");
    }
    else if (!pass) {
      FormValid = false;
      toast.error("Please enter valid password");
    }
    return FormValid;
  }

  const sendAdmin = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (validate()) {
      dispatch(getUserData({
        mobile: mobile,
        password: pass,
      }));
    } else {
      setLoader(false);
    }
  }


  return (
    <>
      <ToastContainer />
      <div className='loginNew'>
        <Container fluid className='h-100'>
          <Row className='h-100'>
            <Col md={6} className='text-center text-white align-items-center justify-content-center left d-none d-md-flex'>
              <div className=''>
                {/* <img src={PCMC} className='img-fluid h-100' alt="Pimpri Chinchwad Muncipal Coorpation"/> */}
              </div>
            </Col>
            <Col md={6} className='d-flex text-center align-items-center justify-content-center right px-2 px-md-5'>
              <Card className="card p-3">
                <div>
                  <img src={PCMC} className='img-fluid' width={100} alt="Pimpri Chinchwad Muncipal Coorpation" />
                </div>
                <h2 className='mb-1 mt-1 noselect'>PCMC SURVEY DASHBOARD</h2>
                <h4 className='mb-1 noselect'><Icon.BoxArrowInRight className="me-2" />Log in</h4>

                <Form className="px-4">
                  <FloatingLabel controlId="floatingInput" label="Mobile" className="mb-1">
                    <Form.Control placeholder="Mobile" className='username' name='contactNo' value={mobile} onChange={(e) => {
                      if (
                        !e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) &&
                        e.target.value.length <= 10
                      ) {
                        setMobile(e.target.value);
                      }
                    }} />
                  </FloatingLabel>

                  <InputGroup data-aos="fade-up" data-aos-delay="200" className="mb-4 noselect">
                    <FloatingLabel controlId="floatingInput" label="Password" className='d-flex'>
                      <Form.Control type={showPassword ? "text" : "password"} placeholder="Password" name='password' value={pass} onChange={(e) => setPass(e.target.value)} />
                      <InputGroup.Text className='user'>{showPassword ? <Icon.EyeSlash size={20} style={{ cursor: 'pointer' }} onClick={() => setshowPassword(!showPassword)} /> : <Icon.Eye size={20} style={{ cursor: 'pointer' }} onClick={() => setshowPassword(!showPassword)} />}</InputGroup.Text>
                    </FloatingLabel>
                  </InputGroup>

                  {loader ? <Spinner animation="border" variant="primary" /> :
                    <Button className="primaryBtn mx-auto" type="submit"
                      onClick={(e) => sendAdmin(e)} data-aos="fade-up" data-aos-delay="300" >
                      <Icon.BoxArrowRight className='me-2' />Login
                    </Button>
                  }

                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </>

  )
}

export default Login;